// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-parceiro-js": () => import("./../../../src/pages/parceiro.js" /* webpackChunkName: "component---src-pages-parceiro-js" */),
  "component---src-pages-solucao-js": () => import("./../../../src/pages/solucao.js" /* webpackChunkName: "component---src-pages-solucao-js" */)
}

